import React from "react";

import Header from 'components/header';
import Footer from 'components/footer';
import Articles from 'components/articles';

import 'style/pages/blog.css';

const Blog = () => {
  return (
    <div className="container min-h-screen">
      <div className="flex flex-col w-full">
        <Header currentPage="blog" />
        <Articles />
        <Footer />
      </div>
    </div>
  );
};

export default Blog;
